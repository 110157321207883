<template>
    <section id='filled'>
        <div class='narrowBox'>
            <h1>תודה שחזרת אלינו!</h1>
            <div>
                <span v-if='$store.state.study.hasCorona'>
                    זו הפעם הרביעית היום שאתה ממלא את השאלון.
                    אם יש בעיה שבגללה הרגשת צורך למלא אותו כל כך הרבה פעמים היום,
                    אפשר להתקשר ישירות לד"ר מיכל הרן - 0507679598
                    ולהתייעץ איתה לגבי מצבך.
                </span>
                <span v-else>
                    לחולים שאינם פעילים אנחנו מאפשרים למלא את השאלון רק פעם אחת.
                    אם יש סיבה שהיית רוצה למלא את השאלון שוב, אפשר לפנות לד"ר מיכל הרן
                    לקבלת קישור חדש בוואטסאפ {{ $store.state.studyPhone }}.
                </span>
                <button type='button' study-button @click='$router.push("final")'>לצפייה שוב במסך הסיום</button>
            </div>
        </div>
    </section>
</template>

<style lang="scss">
#filled {
    padding-top: 3rem;

    & .narrowBox > div {
        padding: 1rem 2rem;
    }

    & button {
        display: block;
        margin: 1.5rem auto 1rem;
    }
}
</style>
